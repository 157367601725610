import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo.svg";
import Menu from "../../Assets/images/icons/menu.svg";
import Close from "../../Assets/images/icons/close.svg";
import Whatsapp from "../../Assets/images/icons/whatsapp.svg";
import "./index.scss";
import { useContext, useState } from "react";
import Context from "../../Context";
const Header = () => {
    const { resumo } = useContext(Context);
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <div id="header">
            <div className="container">
                <Link to="/" title="Cerâmica São Sebastião">
                    <img className="logo" src={Logo} alt="Cerâmica São Sebastião" title="Cerâmica São Sebastião" />
                </Link>
                <button onClick={() => setMenuOpen(!menuOpen)} className={`${menuOpen ? "open" : ""}`}><img src={menuOpen ? Close : Menu} alt="Menu" /></button>
                <div className={`menu ${menuOpen ? "open" : ""}`}>
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="Empresa">A Cerâmica</Link>
                    <Link to="/produtos" title="Produtos">Produtos</Link>
                    <Link to="/contato" title="Entrar em contato">Entrar em contato</Link>
                    {resumo.configuracoes && resumo.configuracoes.telefone && <a href={`https://wa.me/55${resumo.configuracoes.telefone.replace(/\D/g, "")}`} title="Whatsapp" target="_blank" rel="noreferrer noopener">
                        <img src={Whatsapp} alt="Whatsapp" title="Whatsapp" />
                        <span>{resumo.configuracoes.telefone}</span>
                    </a>
                    }
                </div>
            </div>
        </div>
    )
};

export default Header;