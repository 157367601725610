import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo-azul.svg";
import Facebook from "../../Assets/images/icons/facebook.svg";
import Instagram from "../../Assets/images/icons/instagram.svg";
import Linkedin from "../../Assets/images/icons/linkedin.svg";
import Whatsapp from "../../Assets/images/icons/whatsapp.svg";

import { useContext } from "react";
import Context from "../../Context";
import "./index.scss";
const Footer = () => {
    const { resumo } = useContext(Context);
    return (
        <div id="footer">
            <div className="container">
                <Link to="/" title="Cerâmica São Sebastião">
                    <img className="logo" src={Logo} alt="Cerâmica São Sebastião" title="Cerâmica São Sebastião" />
                </Link>
                {resumo.configuracoes &&
                    <div className="socials">
                        {resumo.configuracoes.facebook && resumo.configuracoes.facebook.length > 3 && <a href={resumo.configuracoes.facebook} title="Facebook" target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Facebook" /></a>}
                        {resumo.configuracoes.instagram && resumo.configuracoes.instagram.length > 3 && <a href={resumo.configuracoes.instagram} title="Instagram" target="_blank" rel="noopener noreferrer"><img src={Instagram} alt="Instagram" /></a>}
                        {resumo.configuracoes.linkedin && resumo.configuracoes.linkedin.length > 3 && <a href={resumo.configuracoes.linkedin} title="LinkedIn" target="_blank" rel="noopener noreferrer"><img src={Linkedin} alt="LinkedIn" /></a>}
                    </div>
                }
                <div className="menu">
                    <Link to="/" title="Home">Home</Link>
                    <Link to="/empresa" title="Empresa">A Cerâmica</Link>
                    <Link to="/produtos" title="Produtos">Produtos</Link>
                    <Link to="/contato" title="Entrar em contato">Entrar em contato</Link>
                    {resumo.configuracoes && resumo.configuracoes.telefone && <a href={`https://wa.me/55${resumo.configuracoes.telefone.replace(/\D/g, "")}`} title="Whatsapp" target="_blank" rel="noreferrer noopener">
                        <img src={Whatsapp} alt="Whatsapp" title="Whatsapp" />
                        <span>{resumo.configuracoes.telefone}</span>
                    </a>
                    }
                </div>
            </div>
        </div>
    )
};

export default Footer;