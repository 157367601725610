import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Suspense, lazy, useState, useEffect, useContext } from 'react';
import Context from './Context';

import ScrollToTop from "./Components/ScrollToTop";

import axios from "axios";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
const Cookies = lazy(() => import('./Components/Cookies'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Contato = lazy(() => import('./Pages/Contato'));
const Texto = lazy(() => import('./Pages/Texto'));
const NotFound = lazy(() => import('./Pages/NotFound'));


function App() {
  localStorage.setItem("apiUrl", "/sistema/api/");
  const [resumo, setResumo] = useState({});
  const [modal, setModal] = useState(false);
  const [activePage, setActivePage] = useState(false);
  const value = { resumo, setResumo, modal, setModal, activePage, setActivePage };

  useEffect(() => {
    axios.get(localStorage.getItem("apiUrl") + "configuracoes").then(response => {
      setResumo(response.data.success);
    });
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Router>
          <Switch>
            <Route path="*">
              <Application />
            </Route>
          </Switch>
        </Router>
      </Context.Provider>
    </HelmetProvider>
  );
}

const Application = () => {
  const { resumo } = useContext(Context);
  let location = useLocation();
  return (
    <div className="App">
      <ScrollToTop />
      <Header />
      <Suspense fallback={<div className="suspense"></div>}>
        <TransitionGroup>
          <CSSTransition key={location.key} timeout={300} classNames="fade" mountOnEnter={true} unmountOnExit={true}>
            <Switch location={location}>
              <Route path="/" exact>
                <Home />
              </Route>
              <Route path="/empresa" exact>
                <Empresa />
              </Route>
              <Route path="/produtos" exact>
                <Produtos />
              </Route>
              <Route path="/produto/:slug" exact>
                <Produto />
              </Route>
              <Route path="/contato" exact>
                <Contato />
              </Route>
              {resumo.textos &&
                <Route path="/termos-de-uso" exact>
                  <Texto titulo={"Termos de uso"} texto={resumo.textos.termos_de_uso} />
                </Route>
              }
              {resumo.textos &&
                <Route path="/politica-de-privacidade" exact>
                  <Texto titulo={"Política de privacidade"} texto={resumo.textos.politica_de_privacidade} />
                </Route>
              }
              <Route component={NotFound} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <Cookies />
      </Suspense>
      <Footer />
    </div >
  )
}

export default App;
